import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { capitalize, get, isEmpty, isNil } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import tree from "./tree/local.json";
import { GlobalStateHookOptions } from "./slice.type";

const sliceTrees: any = tree;

const getGroupName = (name: string) => {
  return capitalize(`local_${name}`);
};

const getActionName = (name: string) => {
  return capitalize(`set_${name}`);
};

const generateAllSlices = () => {
  const slices: any = {};
  const reducers: any = {};

  Object.keys(sliceTrees).forEach((groupName) => {
    const group = sliceTrees[groupName];
    const initialState: any = {};
    const reducer: any = {};

    Object.keys(group).forEach((name) => {
      initialState[name] = undefined;

      reducer[getActionName(name)] = (state: any, action: any) => {
        state[name] = action.payload;
      };
    });

    const slice = createSlice({
      name: getGroupName(groupName),
      initialState,
      reducers: reducer,
    });
    slices[slice.name] = slice;
    reducers[slice.name] = slice.reducer;
  });

  return { slices, reducers };
};

export const { slices, reducers } = generateAllSlices();

export const useGlobalState = <T>(
  path: string,
  options?: GlobalStateHookOptions<T>
) => {
  const dispatch = useDispatch();
  const [groupName, action] = useMemo(() => {
    const [name, ...actions] = path.split(".");
    return [name, actions.join(".")];
  }, [path]);
  const state = useSelector<T>((state) =>
    get(state, `${getGroupName(groupName)}.${action}`)
  ) as T;
  const mountRef = useRef(false);

  const update = useCallback(
    (value: any) => {
      //
      try {
        const func =
          slices[getGroupName(groupName)]["actions"][getActionName(action)];
        dispatch(func(value));
      } catch {
        const message = `Seem state ${path} is not defined in slice state/tree/local.json`;
        throw new Error(message);
      }
    },
    [action, dispatch, groupName, path]
  );

  useEffect(() => {
    if (mountRef.current) return;
    if (isNil(options?.initialData) || isEmpty(options?.initialData)) return;
    mountRef.current = true;
    update(options?.initialData);
  }, [options?.initialData, update]);

  return { state, update };
};
